import * as THREE from 'three';
import { gsap } from 'gsap';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { AsciiEffect } from 'three/examples/jsm/effects/AsciiEffect.js';

import glb_src from '../img/smallFirePerson.glb';

export function smallPersonFunction(){

    const canvas = document.getElementById('canvas');

    // 创建一个场景
    const scene = new THREE.Scene();

    // 创建一个渲染器
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);

    // 创建灯光
    const pointLight1 = new THREE.PointLight(0xffffff, 1);
    pointLight1.position.set(0, 100, 200);
    scene.add(pointLight1);
    pointLight1.lookAt(0, 0, 0);

    // 创建相机对象
    const width = window.innerWidth;
    const height = window.innerHeight;
    const k = width / height;
    const s = 8;
    const camera = new THREE.OrthographicCamera(-s * k, s * k, s, -s, 0.1, 2000);
    camera.position.set(0, 3, 20);

    // 窗口大小自动调节
    window.addEventListener('resize', () => {
        renderer.setSize(window.innerWidth, window.innerHeight);
        camera.aspect = window.innerWidth / window.innerHeight;
        effect.setSize(window.innerWidth, window.innerHeight);
        camera.updateProjectionMatrix();
    });

    // 创建 AsciiEffect
    const effect = new AsciiEffect(renderer, ' .@', { invert: true });
    effect.setSize(window.innerWidth, window.innerHeight);
    effect.domElement.style.color = '#4F51B6';

    // 将 AsciiEffect 的 domElement 添加到页面
    canvas.appendChild(effect.domElement);

    // 加载模型
    const loader = new GLTFLoader();
    let model;
    loader.load(glb_src, function (gltf) {
        model = gltf.scene;
        model.scale.set(8, 8, 8);

        scene.add(model);

        // 将模型放置在屏幕中央的位置
        const modelCenter = new THREE.Vector3();
        const modelBox = new THREE.Box3().setFromObject(model);
        modelBox.getCenter(modelCenter);
        model.position.set(0, 0, 0); // 将模型位置调整到中心
        gsap.from(model.scale,{duration:2,x:0,y:0,z:0,ease: "power4.out"})

        // 执行自旋转动画
        let autoRotation = true;
        function toggleAutoRotation() {
            autoRotation = !autoRotation;
        }

        function animateRotation() {
            if (autoRotation && model) {
                model.rotation.y -= 0.005;
            }

            requestAnimationFrame(animateRotation);
        }

        animateRotation();

        // 记录鼠标位置和模型初始旋转角度
        const mouse = new THREE.Vector2();
        const windowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);

        const initialRotation = { x: 0, y: 0 };
        const targetRotation = { x: 0, y: 0 };
        let isMouseMoving = false;

        function onMouseMove(event) {
            mouse.x = (event.clientX - windowHalf.x) * 0.02;
            mouse.y = (event.clientY - windowHalf.y) * 0.02;

            // 平滑滚动效果
            targetRotation.x = mouse.y * 0.3;
            targetRotation.y = mouse.x * 0.6;

            isMouseMoving = true;
        }

        window.addEventListener('mousemove', onMouseMove);

        // 视差效果
        function animateParallax() {
            requestAnimationFrame(animateParallax);

            // 自旋转
            if (!isMouseMoving && model) {
                model.rotation.y -= 0.005;
            }

            // 平滑滚动效果
            if (model) {
                if (isMouseMoving) {
                    initialRotation.y += (targetRotation.y - initialRotation.y) * 0.05;
                    initialRotation.x += (targetRotation.x - initialRotation.x) * 0.05;
                } else {
                    initialRotation.y -= 0.005;
                }

                model.rotation.y = initialRotation.y;
                model.rotation.x = initialRotation.x;

                // 重新计算模型的位置以保持中心点在模型的中心
                const newPosition = modelCenter.clone().applyAxisAngle(new THREE.Vector3(0, 1, 0), -model.rotation.y);
                model.position.copy(newPosition);
            }

            renderer.render(scene, camera);
            effect.render(scene, camera);

            isMouseMoving = false;
        }

        animateParallax();
    });

    // 滚动事件处理程序
    function onScroll(event) {
        // 获取页面滚动距离
        const scrollY = window.scrollY;

        // 计算模型绕X轴旋转角度（可以根据需要调整）
        const rotationX = scrollY * 0.01;

        // 将旋转应用到模型
        if (model) {
            model.rotation.x = rotationX;
        }
    }

    // 监听滚动事件
    window.addEventListener('scroll', onScroll);

    function render() {
        requestAnimationFrame(render);
        renderer.render(scene, camera);
        effect.render(scene, camera);
    }

    render();
}
