import { log } from "console";
import { gsap, ScrollTrigger,ScrollSmoother ,SplitText,MotionPathPlugin} from "gsap/all";
import { start } from "repl";
import $ from "jquery"


import { iconfunction } from "./icon.js";
import { smallPersonFunction } from "./smallPerson.js";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother,SplitText, MotionPathPlugin);

//平滑滚动
const smoother = ScrollSmoother.create({
    wrapper: "#Smooth_Wrapper",
    content: "#Smooth_Content",
    smooth: 5,
    speed: 1,
    effects: true
});

//加载
let atl = gsap.timeline({repeat: -1, yoyo: true})
  atl.to("#txtLoading", { opacity: 0.2 },"<");
  atl.to("#loadIcon", { opacity: 0.2 });

  window.onload = function(){
    let oLoad = document.querySelector('.loader');
    oLoad.style.display = 'none';
    first();
    iconfunction();
    smallPersonFunction();



    //分享图标修改
    let $imgs = $('.st-btn>img').children().prevObject;

    for(let i=0;i<$imgs.length;i++){

        if(i==0){
            $imgs[i].src='/apiTW.svg'
        }else if(i==1){
            $imgs[i].src='/apiIN.svg'
        }else if(i==2){
            $imgs[i].src='/apiFB.svg'
        }else if(i==3){
            $imgs[i].src='/apiRE.svg'
        }else if(i==4){
            $imgs[i].src='/apiALL.svg'
        }
    }

    let $imgs2 = $('.st-left>img').children().prevObject.get(0);
    let $imgs3 = $('.st-right>img').children().prevObject.get(0);

    $imgs2.src = "/apiL.svg"
    $imgs3.src = "/apiR.svg"

  }


//鼠标跟随
gsap.set(".cursor", { xPercent: -50, yPercent: -50 });
gsap.set(".close", { xPercent: -50, yPercent: -50 });

let cursor1 = document.querySelector(".cursor");
let close = document.querySelector(".close");

function updateCursorPosition(e) {
  let clientX, clientY;
  if (e.type === "touchmove") {
    clientX = e.touches[0].clientX;
    clientY = e.touches[0].clientY;
  } else {
    clientX = e.clientX;
    clientY = e.clientY;
  }

  gsap.set(cursor1, { x: clientX, y: clientY });
  gsap.set(close, { x: clientX, y: clientY });
}

window.addEventListener('mousemove', updateCursorPosition);
window.addEventListener('touchmove', updateCursorPosition);
const metal= document.querySelector("#metal");

window.addEventListener('mousedown', (e) => {
  let clientX, clientY;
  if (e.type === "touchstart") {
    clientX = e.touches[0].clientX;
    clientY = e.touches[0].clientY;
  } else {
    clientX = e.clientX;
    clientY = e.clientY;
  }

  if(!metal.paused){
    metal.pause();
    metal.currentTime = 0;
  }

  let tl = gsap.timeline();
  tl.to(".cursor", { duration: 0.2, x: clientX, y: clientY, rotation: -20 })
    .to(".huoxingzi", { duration: 0.01, opacity: 1 }, "<");

    metal.play();
});

window.addEventListener('mouseup', (e) => {
  let clientX, clientY;
  if (e.type === "touchend") {
    clientX = e.changedTouches[0].clientX;
    clientY = e.changedTouches[0].clientY;
  } else {
    clientX = e.clientX;
    clientY = e.clientY;
  }

  let tl = gsap.timeline();
  tl.to(".huoxingzi", { duration: 0.01, opacity: 0 })
    .to(".cursor", { duration: 0.2, x: clientX, y: clientY, rotation: 30 }, "<");

});


////鼠标跟随
function first() {
    const starEase ="back.out(3)";
    const S2Text = document.querySelector(".s2");
    const S3Text = document.querySelector(".s3");
    const S2TextSplit = new SplitText(S2Text, { type: "words"});
    const S3TextSplit = new SplitText(S3Text, { type: "words"});

    const chars = S2TextSplit.words;
    const chars2 = S3TextSplit.words;
    const word4 = chars2[0];


    const firstScreen = gsap.timeline(); 
    //首屏星星+时间卡片出现动画
    firstScreen.set(word4,{zIndex:99,},"<1")
    .from(chars,{duration:0.8,opacity:0,scale:0,ease: starEase,stagger:0.1},"<")
    .from(chars2,{duration:0.8,opacity:0,scale:0,ease: starEase,stagger:0.1},"<")
    .from(".star1",{duration:0.5,opacity:0,scale:0,ease: starEase,},"<")
    .from(".star5",{duration:0.5,opacity:0,scale:0,ease: starEase,},"<0.2")
    .from(".star3",{duration:0.5,opacity:0,scale:0,ease: starEase,},"<0.2")
    .from(".star4",{duration:0.5,opacity:0,scale:0,ease: starEase,},"<0.2")
    .from(".star2",{duration:0.5,opacity:0,scale:0,ease: starEase,},"<0.2")
    .from(".day",{duration:2,y:-100,opacity:0,ease: "power4.out"})
    .from(".firstTime img",{duration:2,y:120,opacity:0,ease: "power4.out"},"<")
    .to(".firstH2",{duration:0.5,opacity:1,ease: "power4.inOut",repeat:-1,yoyo:true,},"<")
}
console.log();

//第二屏元素动画
const twoStep = document.querySelector(".twoStep");
const StepDuration = 5;
const StepEase = "power4.inOut";
const stepTl = gsap.timeline({
    scrollTrigger: {
        trigger: twoStep,
        start: "top bottom-=10%",
        end: "bottom bottom-=10%",
        scrub: 1,
        markers: false,
    },
});

stepTl.from(".twoStep01",{duration:StepDuration,rotationY:90,rotationZ:45,x:-400,ease:StepEase})
.from(".twoStep02",{duration:StepDuration,rotationY:-90,rotationZ:-45,x:400,ease:StepEase},"<");

const twoStep3 = document.querySelector(".twoStep03");
const step3Tl = gsap.timeline({
    scrollTrigger: {
        trigger: twoStep3,
        start: "top bottom",
        end: "top+=20% center+=30%",
        scrub: 1,
        
    },
});

step3Tl.from(twoStep3,{duration:1,scale:1.3,y:200,ease:"power4.inOut"})


//文字拆分打字动画
// 获取需要操作的元素
const textContainer = document.querySelector('.bestText');
const textSplit = new SplitText(textContainer, { type: "chars"});

const textchars = textSplit.chars;

const textTl = gsap.timeline({
    scrollTrigger: {
        trigger: textContainer,
        start: "top bottom-=20%",
        end: "top+=1% bottom-=20%",
        toggleActions: "restart none reverse none",
        // markers: true,
    },
});

textTl.from(textchars, { duration: 0.1, opacity: 0, ease: "power1.inOut", stagger: 0.03 });

// 添加光标效果
const cursorG = document.createElement('span');
cursorG.className = 'cursorG';
cursorG.textContent = '|';
textContainer.appendChild(cursorG);

// 光标闪烁动画
textTl.from(cursorG, { opacity: 0, repeat: -1, yoyo: true, duration: 0.5 });

if (window.innerWidth > 1000) {
    const awardsTl = gsap.timeline({
        scrollTrigger: {
            trigger: ".awards",
            start: "top top",
            end: "bottom top+=100%",
            scrub: 1,
            markers: false,
            pin:".awardsL"
        },
    })
}


//奖品小卡片出现动画
const awardsRThreePrize = document.querySelectorAll(".awardsR-three-prize");
awardsRThreePrize.forEach(e =>{
    const awardsRThreePrizeChild = e.children;
    gsap.from(awardsRThreePrizeChild,{duration:2,y:240,ease: "power4.Out",stagger:0.2,
    scrollTrigger: {
    trigger: e,
    start: "top bottom-=20%",
    end: "bottom bottom-=50%",
    scrub:1,
    // markers: true,
}})    
})


//奖项图标动画
const iconCreative1 = document.querySelector(".iconCreative");
const iconCreative2 = document.querySelector(".iconCreative2");
const iconCreative3 = document.querySelector(".iconCreative3");
let awardsIcon=[iconCreative1,iconCreative2,iconCreative3];



if (window.innerWidth > 1000) {
    awardsIcon.forEach( e => {
        gsap.from(e, {
            duration: 1,
            width:"7vw",
            ease: "elastic.out(1.5)",
    
            scrollTrigger: {
                trigger: e,
                start: "top bottom-=20%",
                end: "top bottom-=20%",
                toggleActions: "restart none reverse none",
                
            }
        });
    });
}
if (window.innerWidth <= 1000) {
    awardsIcon.forEach(e => {
        gsap.from(e, {
            duration: 1,
            width:"7vw",
            ease: "elastic.out(1.5)",
            scrollTrigger: {
                trigger: e,
                start: "top bottom-=20%",
                end: "top bottom-=20%",
                toggleActions: "restart none reverse none",
            
            }
        });
    });
}

//奖项左边按钮动画
let tl = gsap.timeline({repeat: -1, yoyo: true})
tl.to(".awardsL-Event", { scale: 1.1 });

const awardsLEvent = document.querySelector(".awardsL-Event");
const popElementBG = document.querySelector('.pop');
const popElementCard = document.querySelector('.popBG');
const popText = document.querySelector('.popAll');

let awardsLButton = true

awardsLEvent.addEventListener("click",() =>{
    if(awardsLButton){
        awardsLButton = false;
        let tl = gsap.timeline({
            onComplete:()=>{
                awardsLButton = true;
            }
        });
        tl.to(popElementBG,{
            duration:3,
            opacity:1,
            ease: 'power4.out',
            onStart: () => {
        // 在动画开始时显示.pop元素
        popElementBG.style.display = 'flex';},
        })
        .to(popElementCard,{duration:1,scale:1,opacity:1,ease: 'power4.out',},"<")
        .to(popText,{duration:1,scale:1,opacity:1,ease: 'power4.out',},"<")
            
        .to(".cursor",{duration:1,scale:0,opacity:0,ease: 'power4.out',},"<")
        .to(".close",{duration:1,opacity:1,width:"5rem",ease: 'power4.out',},"<")
    }
    
})


popElementBG.addEventListener("click",() =>{
    awardsLButton = false;
    let tl2 = gsap.timeline({
        onComplete:()=>{
            awardsLButton = true;
        }
    });
    tl2.to(popElementBG,{
        duration:1,
        opacity:0,
        ease: 'power4.out',
        onComplete: () => {
      // 在动画开始时显示.pop元素
      popElementBG.style.display = 'none';
    },
    })
    .to(popElementCard,{duration:1,scale:0,opacity:0,ease: 'power4.out',},"<")
    .to(popText,{duration:1,scale:0,opacity:0,ease: 'power4.out',},"<")
     
    .to(".cursor",{duration:1,scale:1,opacity:1,ease: 'power4.out',},"<")
    .to(".close",{duration:1,opacity:0,width:"0rem",ease: 'power4.out',},"<")
})

//按钮移入移出动画
awardsLEvent.addEventListener("mouseenter",() =>{
    gsap.to(awardsLEvent,{
        duration:1,
        ease: 'power4.out',
        "text-shadow":" 0px 0px 10px rgba(175, 254, 0, 1)",
    },)

})

awardsLEvent.addEventListener("mouseleave",() =>{
    gsap.to(awardsLEvent,{
        duration:1,
        ease: 'power4.out',
        "text-shadow":" 0px 0px 0px rgba(175, 254, 0, 0.6)",
    },
    )
})


//模型下载按钮动画
const button1=document.querySelector(".button")
button1.addEventListener("mouseenter",() =>{
    gsap.to(".button",{
        duration:1,
        scale:1.05,
        ease: 'power4.out',
        "text-shadow":" 0px 0px 10px rgba(175, 254, 0, 1)",
    },)

})

button1.addEventListener("mouseleave",() =>{
    gsap.to(".button",{
        duration:1,
        scale:1,
        ease: 'power4.out',
        "text-shadow":" 0px 0px 0px rgba(175, 254, 0, 0.6)",
    },
    )
})




gsap.from(".foot h2",{
    duration:2,
    scale:0,
    ease: "elastic.out(1, 0.3)",
    scrollTrigger: {
        trigger:".foot",
        start: "top+=10% bottom-=60%",
        end: "top+=20% bottom-=60%",
        toggleActions: "restart none reverse none",
        // scrub:1,
        // markers: true,
    }

})

 const L1 = document.querySelector(".L1");
 const L2 = document.querySelector(".L2");
 const L3 = document.querySelector(".L3");
 const L4 = document.querySelector(".L4");
 const L5 = document.querySelector(".L5");
let LeftStar =[L1,L2,L3,L4,L5]

 const R1 = document.querySelector(".R1");
 const R2 = document.querySelector(".R2");
 const R3 = document.querySelector(".R3");
 const R4 = document.querySelector(".R4");
 const R5 = document.querySelector(".R5");

 let RightStar =[R1,R2,R3,R4,R5]

 const footStarTl = gsap.timeline({repeat:-1,scrollTrigger: {
    trigger:".foot",
    start: "top+=10% bottom-=60%",
    end: "top+=20% bottom-=60%",
    toggleActions: "restart none reverse none",
    // scrub:1,
    // markers: true,
}});

footStarTl.from(LeftStar,{
    duration:0.5,
    opacity:0.2,
    ease: 'power4.out',
    stagger:0.1,
})
footStarTl.from(RightStar,{
    duration:0.5,
    opacity:0.2,
    ease: 'power4.out',
    stagger:0.1,
},"<")

footStarTl.to(LeftStar,{
    duration:0.5,
    opacity:0.2,
    ease: 'power4.out',
    stagger:0.1,
})
footStarTl.to(RightStar,{
    duration:0.5,
    opacity:0.2,
    ease: 'power4.out',
    stagger:0.1,
},"<")





const awardsLTitleTl = gsap.timeline();
awardsLTitleTl.to(".Star1", {
    duration: 5, 
    ease: "none",
    repeat:-1,
    motionPath:{
      path: "M251.331 113.711c-2.471 6.836-8.245 12.164-16.671 15.916-8.428 3.754-19.469 5.911-32.375 6.421-25.811 1.019-58.981-4.556-93.381-16.988-34.399-12.431-63.47-29.349-82.666-46.633-9.6-8.643-16.71-17.36-20.791-25.634-4.08-8.272-5.115-16.06-2.645-22.896 2.47-6.836 8.245-12.164 16.671-15.916C27.901 4.227 38.941 2.07 51.848 1.56c25.81-1.02 58.981 4.556 93.38 16.987 34.399 12.432 63.471 29.35 82.667 46.634 9.599 8.643 16.709 17.36 20.791 25.634 4.08 8.272 5.115 16.06 2.645 22.896Z",
      align: "auto",
    //   autoRotate: true,
    offsetX: 20,
    offsetY: -20,
      alignOrigin: [0.5, 0.5],
     
    }
  });

  awardsLTitleTl.to(".Star2", {
    duration: 5, 
    ease: "none",
    repeat:-1,
    motionPath:{
      path: "M251.331 113.711c-2.471 6.836-8.245 12.164-16.671 15.916-8.428 3.754-19.469 5.911-32.375 6.421-25.811 1.019-58.981-4.556-93.381-16.988-34.399-12.431-63.47-29.349-82.666-46.633-9.6-8.643-16.71-17.36-20.791-25.634-4.08-8.272-5.115-16.06-2.645-22.896 2.47-6.836 8.245-12.164 16.671-15.916C27.901 4.227 38.941 2.07 51.848 1.56c25.81-1.02 58.981 4.556 93.38 16.987 34.399 12.432 63.471 29.35 82.667 46.634 9.599 8.643 16.709 17.36 20.791 25.634 4.08 8.272 5.115 16.06 2.645 22.896Z",
      align: "auto",
    //   autoRotate: true,
    offsetX:205,
    offsetY: 110,
    alignOrigin: [0.5, 0.5],
     
    }
    
  },"<2.5");

const imgframe = document.querySelectorAll('.awardsR-three-prize-ul');
imgframe.forEach(e =>{
    const imgstill = e.children[0];
    const imgsports = e.children[1];
    
    imgsports.style.display = 'none';
    
    e.addEventListener('mouseenter',() =>{
        gsap.set(imgstill,{display:'none'})
        gsap.set(imgsports,{display:'block'})
    })
    
    e.addEventListener('mouseleave',() =>{
        gsap.set(imgstill,{display:'block'})
        gsap.set(imgsports,{display:'none'})
    })
})

