import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { gsap } from 'gsap';

export function iconfunction(){
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.set(0, 80, 180);

const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
const pixelRatio = window.devicePixelRatio;
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(pixelRatio);

const canvas = document.querySelector('#icon');
canvas.appendChild(renderer.domElement);

window.addEventListener("resize",() => {
    renderer.setSize( window.innerWidth, window.innerHeight );
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    // 更新轨迹球控制器
    controls.update();
});

// 禁止鼠标控制旋转和缩放
const controls = new OrbitControls(camera, renderer.domElement);
controls.enableRotate = false;
controls.enableZoom = false;

const text = '  #phaetus 10th anniversary  ';
const radius = 50;

const fontLoader = new FontLoader();

// 替换为其他字体文件的 URL
const fontUrl = './Orbitron_Bold.json';

fontLoader.load(fontUrl, (font) => {
    const angleIncrement = (Math.PI * 2) / text.length; // 调整字母间距的角度

    const group = new THREE.Group();

    for (let i = 0; i < text.length; i++) {
        const char = text[i];
        const textGeometry = new TextGeometry(char, {
            font: font,
            size: 15,  // 调整字体大小
            height: 8,  // 调整字体厚度
        });

        textGeometry.computeBoundingBox();
        textGeometry.normalizeNormals();

        const angle = angleIncrement * i;

        const x = Math.cos(angle) * radius;
        const z = Math.sin(angle) * radius;

        const extrudeMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color('#D7FF7E') }); // 红色，厚度颜色
        const surfaceMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color('#4F51B6') }); // 蓝色，表面颜色

        const materials = [extrudeMaterial, surfaceMaterial];

        const mesh = new THREE.Mesh(textGeometry, materials);
        mesh.position.set(x, 0, z);
        mesh.rotation.x = -Math.PI / 2;
        mesh.rotation.z = -angle - Math.PI / 2;
        group.add(mesh);
    }

    group.scale.set(1, 1, 1);
    // 调整整体位置
    scene.add(group);


    
    gsap.from(group.scale, {
        x: 0,
        y: 0,
        z: 0,
        delay: 1,
        duration: 2,
        ease: "power4.out",
    });


    function animate() {
        requestAnimationFrame(animate);
        group.rotation.y += 0.005;
        renderer.render(scene, camera);
    }

    animate();
});

}
