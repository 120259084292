import * as THREE from 'three';
import { gsap } from "gsap";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { AsciiEffect } from 'three/examples/jsm/effects/AsciiEffect.js';



const canvasElement = document.querySelector('.bg');


    // Set up Three.js scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true }); // Set alpha to true
    renderer.setSize(window.innerWidth, window.innerHeight);
    canvasElement.appendChild(renderer.domElement);

// 在创建场景后设置雾效果
scene.fog = new THREE.Fog(0x000000, 100, 1000); // 颜色、起始距离、结束距离

    // Create particles
    const particleCount = 300;
    const particles = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const startPositions = new Float32Array(particleCount * 3); // To store initial positions
    const velocities = new Float32Array(particleCount * 3); // To store particle velocities

    for (let i = 0; i < particleCount; i++) {
      const x = (Math.random() - 0.5) * window.innerWidth;
      const y = (Math.random() - 0.5) * window.innerHeight;
      const z = (Math.random() - 0.5) * 1000;
      positions[i * 3] = x;
      positions[i * 3 + 1] = y;
      positions[i * 3 + 2] = z;
      startPositions[i * 3] = x;
      startPositions[i * 3 + 1] = y;
      startPositions[i * 3 + 2] = z;

      velocities[i * 3] = 0; // No initial x-velocity
      velocities[i * 3 + 1] = 0.5 + Math.random(); // Random y-velocity
      velocities[i * 3 + 2] = 0; // No initial z-velocity
    }

    particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));

    const particleMaterial = new THREE.PointsMaterial({
      color: 0x4F51B6,
      size: 5,
      blending: THREE.AdditiveBlending,
      transparent: true
    });

    const particleSystem = new THREE.Points(particles, particleMaterial);
    scene.add(particleSystem);

    // Apply rotation animation to the particle system
    gsap.to(particleSystem.rotation, { duration: 20, y: Math.PI * 2, repeat: -1, ease: 'linear' });

    // Apply initial Y-direction animation to the particles
    gsap.to(particleSystem.rotation, { duration: 50, y: "-=" + (Math.PI * 2), repeat: -1, ease: 'linear' });

    // Camera position
    camera.position.z = 500;

    // Mouse movement
    const mouse = new THREE.Vector2();
    const windowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);

    function onMouseMove(event) {
      mouse.x = (event.clientX - windowHalf.x) * 0.1;
      mouse.y = (event.clientY - windowHalf.y) * 0.1;
    }

    window.addEventListener('mousemove', onMouseMove);

    // Animation loop
    function animate() {
      requestAnimationFrame(animate);

      // Animate particles in the Y-direction
      for (let i = 0; i < particleCount; i++) {
        positions[i * 3] += velocities[i * 3] * 0.5; // Update x-position
        positions[i * 3 + 1] += velocities[i * 3 + 1] * 0.5; // Update y-position
        positions[i * 3 + 2] += velocities[i * 3 + 2] * 0.5; // Update z-position

        // Wrap particles when they reach the screen edges
        if (positions[i * 3] > window.innerWidth / 2) {
          positions[i * 3] = -window.innerWidth / 2;
        }
        if (positions[i * 3] < -window.innerWidth / 2) {
          positions[i * 3] = window.innerWidth / 2;
        }
        if (positions[i * 3 + 1] > window.innerHeight / 2) {
          positions[i * 3 + 1] = -window.innerHeight / 2;
        }
        if (positions[i * 3 + 1] < -window.innerHeight / 2) {
          positions[i * 3 + 1] = window.innerHeight / 2;
        }
      }

      // Apply parallax effect
      for (let i = 0; i < particleCount; i++) {
        const px = startPositions[i * 3];
        const py = startPositions[i * 3 + 1];
        const pz = startPositions[i * 3 + 2];
        const tx = px + mouse.x;
        const ty = py + mouse.y;
        const tz = pz;
        positions[i * 3] = tx;
        positions[i * 3 + 1] = ty;
        positions[i * 3 + 2] = tz;
      }

      // Update particle positions
      particleSystem.geometry.attributes.position.needsUpdate = true;

      renderer.render(scene, camera);
    }

    animate();