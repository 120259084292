import $ from "jquery"

const timestamp_current = Math.round(new Date() / 1000); //Date.now();  当前时间戵
console.log(timestamp_current);
// 创建一个表示特定日期和时间的实例
const specificDate = new Date('2023-09-31 12:00:00');
// 获取对应的时间戳
const timestamp_target = Math.round(specificDate.getTime()/1000);  //目标时间戵


var intDiff = parseInt(timestamp_target-timestamp_current);//倒计时总秒数量

function timer(intDiff){
    window.setInterval(function(){
        var day=0,
            hour=0,
            minute=0,
            second=0;//时间默认值
        if(intDiff > 0){
            day = Math.floor(intDiff / (60 * 60 * 24));
            hour = Math.floor(intDiff / (60 * 60)) - (day * 24);
            minute = Math.floor(intDiff / 60) - (day * 24 * 60) - (hour * 60);
            second = Math.floor(intDiff) - (day * 24 * 60 * 60) - (hour * 60 * 60) - (minute * 60);
        }
        if (day <= 9) day = '0' + day;
        if (hour <= 9) hour = '0' + hour;
        if (minute <= 9) minute = '0' + minute;
        if (second <= 9) second = '0' + second;
        $('#day_show').html(day);
        $('#hour_show').html(hour);
        $('#minute_show').html(minute);
        $('#second_show').html(second);
        intDiff--;
    }, 1000);
}
$(function(){
    timer(intDiff);
});